import classNames from 'classnames';
import React, { useEffect } from 'react';

type RadioProps = {
  alignment?: 'right' | 'left';
  /** passthrough function for announcer */
  announce?: () => void;
  children?: TSFix;
  className?: string;
  /** input label property value */
  label: React.ReactNode | string;
  /** input name property value */
  name: string;
  /** input onChange function */
  onChange: () => void;
  /** input selected property value */
  selected: boolean;
  /** additional descriptive text for block theme, overrides label for default */
  text?: string;
  /** radio theme */
  theme?: 'block' | 'default' | 'blockOutline' | 'ghostBlock' | 'blockRight';
  value?: string;
  role?: string;

  /** deprecated */
  innerClassName?: string | undefined;
  inputClassName?: string | undefined;
  labelClassName?: string | undefined;
  size?: 'default' | 'small';
};

const radioThemeMap = (t: string) => {
  switch (t) {
    case 'blockOutline':
    case 'ghostBlock':
    case 'blockRight':
      return 'block';
    default:
      return t;
  }
};

const Radio = ({
  alignment = 'left',
  announce,
  children,
  className,
  theme: themeProp = 'default',
  selected = false,
  label,
  name,
  onChange,
  text,
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size = 'default',
  ...rest
}: RadioProps) => {
  useEffect(() => {
    if (announce) announce();
  }, [announce]);

  const theme = radioThemeMap(themeProp);

  // TODO: Make these updates in FK directly
  const labelInner =
    theme === 'block' ? (
      children ? (
        <span
          className={classNames(
            'fk-blockRadio__inner',
            { '-right': alignment === 'right' },
            { '-children': !!children },
          )}
        >
          {children}
          <div className="flex-column overflow-x-auto">
            <span className="fk-blockRadio__innerTitle">{label}</span>
            {text && <span className="fk-blockRadio__innerText">{text}</span>}
          </div>
        </span>
      ) : (
        <span className={classNames('fk-blockRadio__inner', { '-right': alignment === 'right' })}>
          <span className="fk-blockRadio__innerTitle">{label}</span>
          {text && <span className="fk-blockRadio__innerText">{text}</span>}
        </span>
      )
    ) : (
      text || label
    );

  return (
    <label
      className={classNames(
        `fk-radio fk-radio__${theme}`,
        className,
        { '-selected': selected },
        { '-right': alignment === 'right' },
      )}
    >
      <input name={name} onChange={onChange} checked={selected} type="radio" value={value} {...rest} />
      {labelInner}
    </label>
  );
};

export default Radio;
